.slidtext {
    width: 100%;
    height: auto;
    margin: auto;
    overflow: hidden;
}

.slidtext .slide {
    display: flex;
    animation: scroll 10s linear infinite;
    -webkit-animation: scroll 10s linear infinite;
    width: calc(100px * 14);
}

/* .slidtext .slide img {
    width: 100%;
} */

@keyframes scroll {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    100% {
        -webkit-transform: translateX(calc(-100px * 9));
        transform: translateX(calc(-80px * 9));
    }
}